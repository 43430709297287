import React, { useRef, useEffect, useState } from 'react';
import whatsapp from '../assets/whatsapp.png';
import logo from '../assets/logo.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import right from '../assets/right-arrow.png';
import left from '../assets/left-arrow.png';
import $ from "jquery";
import cloudLeft from '../assets/cloud-left.png';
import cloudRight from '../assets/cloud-right.png';
import pulse from '../assets/pulse.png';

const services = () => {
    window.location.href = "https://creaters.pages.dev/services";
}

const HorizontalScroll = () => {
    const containerRef = useRef(null);
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [enterBtn, setEnter] = useState(0);
    const [showClouds, setShowClouds] = useState(false);
    const [_newMarginLeft, setNewMarginLeft] = useState();
    const [_newMarginLeft2, setNewMarginLeft2] = useState();
  

    async function handleEnterClick() {
        setEnter(1);  // This sets the state to 1 when clicked
    }

    useEffect(() => {
        const screenHeight = window.innerHeight;
    
        // Calculate new width based on height
        const newWidth = screenHeight * (2880 / 540) * 91.5 / 100;
        const newHeight = 91.5;
        const newMarginLeft = newWidth * 10663 / 11520;
        setNewMarginLeft(newMarginLeft);

        const newMarginLeft2 = newWidth * 10663 / 11520;
        setNewMarginLeft2(newMarginLeft2);

        console.log("newMarginLeft: " + newMarginLeft);

        // Update 'landMain' dimensions
        const landMain = document.getElementById('landMain');
        if (landMain) {
            landMain.style.width = `${newWidth}px`;
            landMain.style.height = `${newHeight}vh`;
            landMain.style.backgroundRepeat = 'no-repeat';
        }


        const clouds1 = document.getElementById('clouds1');
        if (clouds1) {
            clouds1.style.width = `${newWidth}px`;
            clouds1.style.height = `${newHeight}vh`;
            clouds1.style.backgroundRepeat = 'no-repeat';
        }
    
        
        const clouds2 = document.getElementById('clouds2');
        if (clouds2) {
            clouds2.style.width = `${newWidth}px`;
            clouds2.style.height = `${newHeight}vh`;
            clouds2.style.backgroundRepeat = 'no-repeat';
        }
    
        // Function to apply the same width to all elements with a certain class
        const setClassWidth = (className) => {
            const elements = document.getElementsByClassName(className);
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.width = `${newWidth}px`;
            }
        };

        
   
    
        // Apply width to each background image class
        setClassWidth('img1BG');
        setClassWidth('img2BG');
        setClassWidth('img3BG');
        setClassWidth('img4BG');
        setClassWidth('img5BG');
        setClassWidth('img6BG');
        setClassWidth('img7BG');
        setClassWidth('img8BG');
        setClassWidth('img9BG');
        setClassWidth('img10BG');
        setClassWidth('img11BG');
        setClassWidth('img12BG');
        setClassWidth('img13BG');
        setClassWidth('img14BG');


        /*setClassHeight('cloud-left-2');*/

        
        console.log("newWidth : " + newWidth);
        console.log("screenHeight : " + screenHeight);
    }, []);
    

    /*useEffect(() => {
        // Simulate the page loading process and fade out loader
        const timer = setTimeout(() => {
            setIsLoading(false); // Page is loaded, hide loader
        }, 3000);  // Simulates a 3 second loader

        return () => clearTimeout(timer); // Cleanup timeout on unmount
    }, []);*/

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const detailsEggs = [
        {
            id: 'landsBG',
            title: 'Buildverse',
            description: 'Our institute offers comprehensive 3D animation courses designed to equip students with the skills and knowledge needed to excel in the animation industry. Our curriculum covers a wide range of topics, including character modeling, rigging, texturing, and animation principles. With state-of-the-art facilities and experienced instructors, students gain hands-on experience using industry-standard software and tools. Whether youre a beginner or looking to advance your skills, our courses provide a solid foundation and the creative environment needed to bring your animated visions to life. Join us to turn your passion for animation into a rewarding career.',
            imgSrc: 'https://newbgs.pages.dev/imgs/IMG_4397.png',
             link: 'buildverse'
        },
        {
            id: 'landsBG',
            title: 'Education',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://lands-imgs.pages.dev/imgs/IMG_4406.PNG',
             link: 'learning'
        },
        {
            id: 'landsBG',
            title: 'Gaming Services',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://lands-imgs.pages.dev/imgs/IMG_4413.PNG',
            link: 'services'
        },
    ];

    const showNext = () => {
        setCurrentIndex((currentIndex + 1) % detailsEggs.length);
    };

    const showPrevious = () => {
        setCurrentIndex((currentIndex - 1 + detailsEggs.length) % detailsEggs.length);
    };

    const handleEggClick = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const handleWheel = (e) => {
            const container = containerRef.current;
            if (container) {
                container.scrollLeft += e.deltaY;
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);


    return (
        <div className='bgNew'>

            {/*enterBtn < 1 ? (
                <div className="page-loader3">
                    <img src={enter} alt="Enter" onClick={handleEnterClick} />
                </div>
            ) : (
            )
        */}

                 <img
                    className="cloud-left-2"
                     
                    src={cloudLeft}
                    alt="Cloud Left"
                />
                <img
                    className="cloud-right-2"
                    src={cloudRight}
                    alt="Cloud Right"
                  
                />
 
            <div className='imgsMainDiv' ref={containerRef}>
                {/**<div className='sky'></div>*/}

                {/*<div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv">Services</div>
                            <div class="navDiv">Contact Us</div>
                            <div class="navDiv">Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                        </div>

                    </div>

                </div>*/}
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv">Services</div>
                        <div class="navDiv">Contact Us</div>
                        <div class="navDiv">Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                    </div>) : null}
                <div id='landMain'>
                    {/* <img className='img1' src={land1} />*/}

                    <img className='imgPulse2' onClick={() => window.location.href = 'buildverse'} src={pulse}
                    ></img>
                    <div className='img1BG' onClick={() => window.location.href = 'buildverse'} ></div>
                  
                    <div className='img2BG'></div>
                    <div className='img3BG'></div>
                    <div className='img4BG'></div>
                    <div className='img5BG'></div>
                    <div className='img6BG'></div>
                    <div className='img8BG'></div>
                    <div className='img9BG'></div>
                    <div className='img10BG'></div>
                    <div className='img11BG'></div>
                    <div className='img12BG'></div>
                    <img className='imgPulse' onClick={() => window.location.href = 'services'} src={pulse}
                    style={{ marginLeft: _newMarginLeft }}
                    ></img>
                    <div className='img13BG'></div>
                    <div className='img14BG'></div>

                    <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

                    {/*<img className='bgImgs' src='https://lands-imgs.pages.dev/imgs/bg.png' />*/}
                    {/*<img className='bgImgs' src="https://imgs-dtn.pages.dev/imgs/bgHome.png" />*/}

                    <div className='landD'>
                        <div className='landH'>Welcome to Our Spaces</div>
                        <p className='landCon'>Discover Gold Verse, a premier space for luxurious living and elite experiences. Explore EduSphere, our hub for innovative learning and educational excellence. Dive into Game Haven, your ultimate destination for immersive gaming adventures. Welcome to a world of endless possibilities.</p>
                    </div>
                </div>
            </div>

            {/* MOBILE AREA*/}
            <div className='allwrapLands'>

                <div className="headers">
                    <div className="headers2">
                        <div className="logo" onClick={() => window.location.href = '/'}>
                            <img className="logoPic" onClick={() => window.location.href = '/'} src={logo} alt="Logo" />
                        </div>
                        <div className="right">
                            <div className="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div className="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                            <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div className="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                        </div>
                    </div>
                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                    </div>) : null}


                <div className='landsSection'>
                    <div className='carouselContainerLands'>
                        <img className='arrow left' onClick={showPrevious} src={left} alt="Previous" />
                        <div className='eggsSection'>
                            {detailsEggs.map((egg, index) => (
                                <div className={`detailsEgg ${index === currentIndex ? 'active' : ''}`} key={egg.id}>
                                    <div className={egg.id}>
                                        <div className='eggT'>{egg.title}</div>
                                        <a href={egg.link} target="_blank" rel="noopener noreferrer"><img className='landImg' src={egg.imgSrc} alt={egg.title} /></a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img className='arrow right-2' onClick={showNext} src={right} alt="Next" />
                    </div>

                </div>

                <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} alt="WhatsApp" /></div>
            </div>
            {/* MOBILE AREA*/}

        </div>
    );
};

export default HorizontalScroll;
