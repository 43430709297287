import logo from '../assets/logo.png';
import section1Img from '../assets/IMG_2034.PNG';
import whatsapp from '../assets/whatsapp.png';
import React, { useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import right from '../assets/right-arrow.png';
import left from '../assets/left-arrow.png';

const Learning = () => {
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const detailsEggs = [

        {
            id: 'detailsEggSub3',
            title: 'Graphic Designer',
            description: 'Offers a transformative journey through the art of visual communication, guiding students to master typography, color theory, and layout, while cultivating a keen eye for branding and aesthetic appeal. From logos to print design and digital media, students will learn to craft visually stunning designs that leave a lasting impact.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Graphicdesigner.PNG'
        },
        {
            id: 'detailsEggSub2',
            title: 'Digital Marketing ',
            description: 'Equips students with the power to navigate the ever-evolving online landscape. They will master the art of SEO, social media marketing, email campaigns, and data-driven analytics, gaining the tools to build captivating digital strategies that connect brands with audiences across the globe.',
            imgSrc: 'https://eggs-8eh.pages.dev./imgs/digitalMarketing.PNG'
        },
        {
            id: 'detailsEggSub5',
            title: 'Digital Art',
            description: 'Taps into the boundless world of digital illustration, concept art, and character design. Using industry-standard software like Adobe Illustrator, students will bring their imaginative creations to life, developing unique visual styles that captivate and inspire.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/IMG_2008.png'
        },
        {
            id: 'detailsEggSub4',
            title: '3D Digital Art',
            description: 'Opens the door to sculpting immersive worlds and hyper-realistic characters, teaching students how to model, texture, and render in software like Blender or Maya, allowing them to build intricate 3D masterpieces that push creative boundaries.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/IMG_2007.png'
        },
        {
            id: 'detailsEggSub9',
            title: 'Video Editing',
            description: 'Immerses students in the exciting world of storytelling through post-production, where they will master cutting-edge techniques, color grading, sound design, and visual effects, crafting seamless narratives that captivate viewers.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Videoediting.png'
        },
        {
            id: 'detailsEggSub10',
            title: 'Visual Effects (VFX)',
            description: 'Dives deep into the magic of cinema, where students will explore motion tracking, compositing, and CGI integration, enabling them to create mind-blowing special effects that transport audiences to new worlds.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Visual%20fx.PNG'
        },
        {
            id: 'detailsEggSub',
            title: '3D Animation',
            description: 'Takes students on a journey into the realm of animation, where they’ll learn to breathe life into characters, mastering rigging, motion, and environment animation to produce captivating and lifelike animated sequences.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/3danimation.PNG'
        },
        {
            id: 'detailsEggSub2',
            title: 'Game Development',
            description: 'Offers the ultimate gateway into the booming gaming industry, where students will create their own interactive worlds, designing gameplay mechanics, coding in powerful engines like Unity or Unreal, and crafting immersive experiences that enthrall players.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Game%20development.PNG'
        },

        {
            id: 'detailsEggSub6',
            title: 'Metaverse Architecture',
            description: 'Provides students the opportunity to step into the future of virtual environments, designing immersive spaces within the rapidly growing metaverse. With a focus on VR/AR integration and interactive world-building, this course prepares students to lead the charge in this exciting frontier.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Metaverse.PNG'
        },

        {
            id: 'detailsEggSub8',
            title: 'UI/UX Design',
            description: 'Focuses on building intuitive and visually compelling user experiences. Students will learn how to create seamless interfaces through wireframing, prototyping, and testing, ensuring their designs provide users with an engaging and effortless journey across digital platforms.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Uiux.PNG'
        },

        {
            id: 'detailsEggSub7',
            title: 'Technocrat',
            description: 'In collaboration with Vignan, we bring a robust lineup of tech courses designed to address the needs of modern industries. Our offerings include Full Stack Development, which covers front-end and back-end web technologies, and AI and Machine Learning, providing in-depth knowledge of intelligent systems. Internet of Things (IoT) teaches connectivity between devices, while Cyber Security focuses on protecting digital infrastructures. We also offer advanced business and finance tech programs such as FinTech and Financial Markets, Data Science and Analytics, and Robotics & Automation, ensuring students develop industry-leading technical skills. This collaboration ensures a balance between creativity and technology, providing students with comprehensive expertise across multiple disciplines.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Mobile%20app%20development.PNG'
        },
    ];

    const showNext = () => {
        setCurrentIndex((currentIndex + 1) % detailsEggs.length);
    };

    const showPrevious = () => {
        setCurrentIndex((currentIndex - 1 + detailsEggs.length) % detailsEggs.length);
    };

    const handleEggClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className='allwrapAbout'>
            <div className='allwrapAbout2'>
                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} onClick={() => window.location.href = '/'} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                            <div class="navDiv"><span className='onDiv'>Learning</span></div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                        </div>

                    </div>

                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div class="navDiv"><span className='onDiv'>Learning</span></div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}
                    <div className='eggsSection'>
                <div className='carouselContainer'>
                    <img className='arrow left' onClick={showPrevious} src={left} alt="Previous" />
                    <div className='eggsSection'>
                        {detailsEggs.map((egg, index) => (
                            <div className={`detailsEgg ${index === currentIndex ? 'active' : ''}`} key={egg.id}>
                                <div className={egg.id}>
                                    <img className='eggMain' src={egg.imgSrc} alt={egg.title} />
                                    <div className='contentsEgg'>
                                        <div className='eggT'>{egg.title}</div>
                                        <p className='eggP'>{egg.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <img className='arrow right-2' onClick={showNext} src={right} alt="Next" />
                </div>

                <div className='littleEggsMain'>
                    {detailsEggs.map((egg, index) => (
                        <div className='littleEggs' key={egg.id} onClick={() => handleEggClick(index)}>
                            <img src={egg.imgSrc} alt={egg.title} />
                            <div>{egg.title}</div>
                        </div>
                    ))}
                </div>
            </div>
            </div>

            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

        </div>
    );
};

export default Learning;